/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateInstallments } from '../models/CreateInstallments';
import type { CreateMakePayment } from '../models/CreateMakePayment';
import type { CreatePrepareNextPayment } from '../models/CreatePrepareNextPayment';
import type { InstallmentResult } from '../models/InstallmentResult';
import type { Order } from '../models/Order';
import type { PaymentMethodResult } from '../models/PaymentMethodResult';
import type { PaymentPreview } from '../models/PaymentPreview';
import type { PaymentResult } from '../models/PaymentResult';
import type { UpdateOrder } from '../models/UpdateOrder';
import type { UpdatePaymentMethod } from '../models/UpdatePaymentMethod';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * Updates a payment for an order
     * @param requestBody
     * @returns PaymentMethodResult Success
     * @throws ApiError
     */
    public static updatePaymentMethod(
        requestBody?: UpdatePaymentMethod,
    ): CancelablePromise<PaymentMethodResult> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orders/update-payment-method',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a next payment for an order using the specified payment method.
     * @param requestBody
     * @returns PaymentPreview Success
     * @throws ApiError
     */
    public static createNextPayment(
        requestBody?: CreatePrepareNextPayment,
    ): CancelablePromise<PaymentPreview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/prepare-next-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a payment for an order using the specified payment method.
     * @param requestBody
     * @returns PaymentResult Success
     * @throws ApiError
     */
    public static createPayment(
        requestBody?: CreateMakePayment,
    ): CancelablePromise<PaymentResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/make-payment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Checks out order as offline and sets order status to waiting_for_payment.
     * @param id
     * @returns Order Success
     * @throws ApiError
     */
    public static offlineCheckout(
        id: number,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/{id}/offline-checkout',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Creates an installment given order id
     * @param id
     * @param requestBody
     * @returns InstallmentResult Success
     * @throws ApiError
     */
    public static createInstallments(
        id: number,
        requestBody?: CreateInstallments,
    ): CancelablePromise<InstallmentResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/{id}/installments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
            },
        });
    }

    /**
     * Loads a order by id
     * @param id
     * @returns Order Success
     * @throws ApiError
     */
    public static getOrder(
        id: number,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates an order
     * @param id
     * @param requestBody
     * @returns Order Success
     * @throws ApiError
     */
    public static updateOrder(
        id: number,
        requestBody?: UpdateOrder,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/orders/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
